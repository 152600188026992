const RoomStatuses = {
  addingOptions: "ADDING_OPTIONS",
  dotVoting: "DOT_VOTING",
  results: "RESULTS"
};

// optionList = [
//   {
//     name: "sldfkj",
//     votes: [
//       {
//         person: "Name",
//         number: 3
//       }
//     ]
//   }
// ];

// room = {
//   options: [],
//   Users: [
//     {
//       userName: "whatever",
//       votes: ["place1", "place2"]
//     }
//   ]
// };

module.exports = { RoomStatuses };
